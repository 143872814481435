<template>
    <div>
        <div v-if="!item.carChargeData && !item.chargerHistory">
            <div class="pa-2 rounded-lg critical white--text" block v-if="item.status != 'CANCELED'">
                Pagamento não associado a carregador
            </div>
            <div class="pa-2 rounded-lg white black--text elevation-12" block v-else>
                🤖 Pagamento não associado a carregador, foi cancelado.
            </div>
        </div>

        <div class="pa-2 rounded-lg critical white--text" block v-if="item.status == 'CANCELED' && item.value > 0">
            Valor deveria ser zero.
        </div>

        <div class="pa-2 rounded-lg critical white--text" block v-if="!item.config">
            Configuração não localizada
        </div>
        <div :class="`pa-2 rounded-lg ${carChargeData.color} ${carChargeData.secondColor}--text`" block v-if="carChargeData && carChargeData.status != null">
            <v-row>
                <v-col cols="6">
                    <v-icon left :color="carChargeData.secondColor">{{ carChargeData.icon }}</v-icon>
                    {{ carChargeData.status }}
                </v-col>
                <v-col cols="6">
                    <strong class="ml-2">
                        {{ carChargeData.consumption ? Number.parseFloat(carChargeData.consumption).toFixed(2) : 0 }} kWh
                    </strong>
                    <strong class="ml-2">
                        R$ {{ getPrice(carChargeData.consumption) }}
                    </strong>
                </v-col>
            </v-row>
        </div>

        <div :class="`pa-2 rounded-lg ${chargerHistory.color} ${chargerHistory.secondColor}--text`" v-if="chargerHistory && chargerHistory.status != null">
            <v-row>
                <v-col cols="6">
                    <v-icon left :color="chargerHistory.secondColor">{{ chargerHistory.icon }}</v-icon>
                    {{ chargerHistory.status }}
                </v-col>
                <v-col cols="6">
                    <strong class="ml-2">
                        {{ chargerHistory.consumption ? Number.parseFloat(chargerHistory.consumption).toFixed(2) : 0 }} kWh
                    </strong>
                    <strong class="ml-2">
                        R$ {{ getPrice(chargerHistory.consumption) }}
                    </strong>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import HistoryStatus from "@/enum/HistoryStatus";
import statusCharger from "@/helpers/StatusCharger";

export default {
    props: {
        item: {
            type: Object,
        },
    },

    data() {
        return {
            statusCharger,
        };
    },

    computed: {
        carChargeData() {
            let ret = { status: null, color: null, secondColor: null, icon: null, consumption: null };

            if (this.item.carChargeData) {
                if (this.item.carChargeData.charger.data.online == 1) {
                    ret = this.statusCharger.getComponentStyle(this.item.carChargeData.status);
                } else {
                    ret = { status: "Offline", color: "grey", secondColor: "white", icon: "cloud_off" };
                }
                ret.consumption = this.item.carChargeData.consumption;
            }
            return ret;
        },
        chargerHistory() {
            let ret = { status: null, color: null, secondColor: null, icon: null, consumption: null };
            if (this.item.chargerHistory) {
                if (this.item.chargerHistory.status == HistoryStatus.PROCESSED) {
                    ret.status = "Processado";
                    ret.color = "success";
                    ret.icon = "done_outline";
                    ret.secondColor = "white";
                } else if (this.item.chargerHistory.status == HistoryStatus.NEED_PROCESS && this.item.chargerHistory.counter < 2) {
                    ret.status = "Aguardando processamento";
                    if (this.item.chargerHistory.counter == null) {
                        ret.color = "success";
                        ret.icon = "pending";
                        ret.secondColor = "white";
                    } else {
                        ret.color = "warning";
                        ret.icon = "warning";
                        ret.secondColor = "white";
                    }
                } else if (this.item.chargerHistory.status == HistoryStatus.NEED_PROCESS) {
                    ret.status = "Necessária ação do operador";
                    ret.color = "critical";
                    ret.icon = "error_outline";
                    ret.secondColor = "white";
                } else if (this.item.chargerHistory.status == HistoryStatus.PROCESSED_MANUAL) {
                    ret.status = "Processado manualmente.";
                    ret.color = "white";
                    ret.icon = "warning";
                    ret.secondColor = "black";
                } else if (this.item.chargerHistory.status == HistoryStatus.PROCESSED_MANUAL_ERROR) {
                    ret.status = "Necessário validação com a plataforma oficial do operador";
                    ret.color = "error";
                    ret.icon = "warning";
                    ret.secondColor = "white";
                }
                ret.consumption = this.item.chargerHistory.consumption;
            }
            return ret;
        }
    },

    methods: {
        getPrice(consumption) {
            if (this.item.config && this.item.config.pricePolicy) {
                if (this.item.config.pricePolicy == "PAYMENT_PER_CONSUMPTION") {
                    return Number.parseFloat(this.item.config.priceKwh * consumption).toFixed(2);
                }
                return Number.parseFloat(this.item.config.price * consumption).toFixed(2);
            }
            return 0;
        },
    },
};
</script>
